<template>
  <div>
    <v-dialog
            :model-value="!game?.status"
            width="auto"
            persistent
    >
        <v-card
                title="Game option"
                class="px-3"
        >
            <v-card-actions class="d-grid">
                <v-checkbox
                        v-model="has_plus_0_color"
                        color="blue"
                        prepend-icon="mdi-numeric-0-circle-outline"
                        label="+0 color"
                ></v-checkbox>
                <v-checkbox
                        v-model="has_plus_0_black"
                        color="black"
                        prepend-icon="mdi-numeric-0-circle-outline"
                        label="+0 black"
                ></v-checkbox>
                <v-checkbox
                        v-model="has_plus_4_color"
                        color="blue"
                        prepend-icon="mdi-numeric-4-circle-outline"
                        label="+4 color"
                ></v-checkbox>
                <v-checkbox
                        v-model="has_plus_2_black"
                        color="black"
                        prepend-icon="mdi-numeric-2-circle-outline"
                        label="+2 black"
                ></v-checkbox>
                <v-checkbox
                        v-model="has_purple"
                        color="purple"
                        prepend-icon="mdi-palette"
                        label="Purple"
                ></v-checkbox>
                <v-text-field
                        v-model="bot_number"
                        label="Bot number"
                        prepend-icon="mdi-robot"
                        variant="plain"
                        type="number"
                        :min="0"
                        :max="9"
                ></v-text-field>
            </v-card-actions>
            <v-card-actions>
                <v-btn
                        @click="start_game"
                        class="mx-auto"
                        text="Start game"
                        color="blue"
                        variant="tonal"
                ></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "GameOptionView",
    data: () => ({
        has_purple: true,
        has_plus_0_black: true,
        has_plus_0_color: true,
        has_plus_2_black: true,
        has_plus_4_color: true,
        bot_number: 0
    }),
    computed: {
        ...mapState(["game"]),
        get_option_game() {
            return {
                has_purple: this.has_purple,
                has_plus_0_black: this.has_plus_0_black,
                has_plus_0_color: this.has_plus_0_color,
                has_plus_2_black: this.has_plus_2_black,
                has_plus_4_color: this.has_plus_4_color,
                bot_number: parseInt(this.bot_number)
            }
        }
    },
    methods: {
        start_game() {
            this.$emit("start_game", this.get_option_game)
        }
    }
}
</script>

<style scoped>
.d-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 40px;
}
@media screen and (max-width: 900px) {
    .d-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}
</style>