<template>
  <div id="deck">
      <div class="position-relative card reverse ml-auto">
          <CardHiddenView
                  v-if="deck_length >= 2"
                  :style="deck_length >=3 ? 'box-shadow: -5px -5px 10px var(--dark);':''"
                  class="position-absolute"
          ></CardHiddenView>
          <CardHiddenView
                  v-if="deck_length"
                  id="draw"
                  @click.exact="draw_card"
                  class="position-absolute translateY"
          ></CardHiddenView>
      </div>
      <CardShowView class="mr-auto"></CardShowView>
  </div>
</template>

<script>
import CardHiddenView from "@/components/CardHiddenView.vue";
import CardShowView from "@/components/CardShowView.vue";
import {mapState} from "vuex";

export default {
    name: "DeckView",
    components: {CardShowView, CardHiddenView},
    computed: {
      ...mapState(["game"]),
        deck_length() {
          const length = this.game?.deck?.length
          return length ? length : 0;
        }
    },
    methods: {
        draw_card() {
            // todo animate
            // const card = document.getElementById("draw");
            // card.animate(
            //     [
            //         {
            //             transform: "translateY(1000px)"
            //         }
            //     ],
            //     {
            //         duration: 500
            //     });
            this.$emit("draw_card");
        }
    }
}
</script>

<style scoped>
#deck {
    display: flex;
    gap: 10px;
    padding: 10px;
    position: absolute;
    inset: 0;
    align-items: center;
    overflow: hidden;
    margin-bottom: 100px;
}
.card {
    margin-left: 0;
}
.reverse {
    overflow: visible;
    z-index: 20;
}
.translateY:hover {
    /*transform: translateY(20px);*/
}
</style>