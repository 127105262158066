<template>
  <div
      v-if="!!info"
      class="dialog-info"
  >
      <v-card
          title="Information"
          class="px-3 mx-auto mb-auto animate"
      >
          <v-card-text class="d-flex">
              <v-icon
                  v-if="info?.icon"
                  :icon="`mdi-${info.icon}`"
                  class="mr-2"
                  :color="info?.color"
              ></v-icon>
              <div v-if="info?.text">{{info?.text}}</div>
          </v-card-text>
          <v-card-actions>
              <v-btn
                  @click="reset_info"
                  class="mx-auto"
                  text="Close"
                  color="red"
                  variant="tonal"
              ></v-btn>
          </v-card-actions>
      </v-card>
  </div>
</template>

<script>
export default {
    name: "InfoView",
    props: {
      info: Object
    },
    methods: {
        reset_info() {
            this.$emit("reset_info")
        }
    }
}
</script>

<style scoped>
.dialog-info {
    display: grid;
    position: absolute;
    padding: 15px;
    inset: 0;
}
.animate {
    z-index: 2500;
    animation: translate 100ms;
}
@keyframes translate {
    from {
        transform: translateY(-500px);
    }
}
</style>