<template>
  <div class="hand">
      <v-btn
              @click="skip_turn"
              v-if="get_can_skip"
              class="mb-2"
              text="Skip turn"
              color="blue"
      ></v-btn>
      <div class="d-flex mx-auto">
          <CardView
                  @play_card="play_card"
                  v-for="(card, index) in get_hand"
                  :key="index"
                  :card="card"
                  :class="card.class"
          ></CardView>
      </div>
      <div>
          <v-progress-linear
                  :model-value="time_afk"
                  :min="0"
                  :max="get_max_time_afk"
                  color="blue"
                  class="mt-2"
          ></v-progress-linear>
      </div>
  </div>
</template>

<script>
import CardView from "@/components/CardView.vue";
import {mapState} from "vuex";

export default {
    name: "HandView",
    props: {
        current_pseudo: String
    },
    components: {CardView},
    computed: {
        ...mapState(["game", "time_afk"]),
        get_max_time_afk() {
            if (this.game?.option_game?.time_afk)
                return this.game.option_game.time_afk
            return 0
        },
        get_hand() {
            let hand;
            if (this.current_pseudo) {
                hand = this.game?.players?.find(player => player.pseudo === this.current_pseudo)?.hand
            } else {
                hand = this.game?.players?.find(player => player)?.hand
            }
            return hand ? hand : []
        },
        get_can_skip() {
            if (!this.current_pseudo) return false
            const index_player = this.game?.players?.findIndex(player => player.pseudo === this.current_pseudo)
            if (index_player !== 0) return false
            return this.game?.players?.at(index_player)?.can_skip
        }
    },
    methods: {
        play_card(card) {
            this.$emit("play_card", card)
        },
        skip_turn() {
            this.$emit("skip_turn")
        }
    }
}
</script>

<style scoped>
.hand {
    display: grid;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 15px;
    overflow-x: auto;
    overflow-y: hidden;
}
.card_draw {
    animation: 250ms draw;
}
@keyframes draw {
    from {
        transform: translateY(500px);
    }
}
</style>