import { createStore } from 'vuex'

export default createStore({
  state: {
    game: {},
    time_afk: 0,
    card_deck: {
      text: "",
      color: "",
      change_color: ""
    },
    current_card: null
  },
  getters: {
  },
  mutations: {
    update_game(state, game) {
      state.game = game
      state.time_afk = 0
    },
    update_card_deck(state, card) {
      state.card_deck = card
    },
    set_current_card(state, card) {
      state.current_card = card
    },
    add_time(state) {
      state.time_afk += 1
    },
    reset_interval(state) {
      state.time_afk = 0
    }
  },
  actions: {
  },
  modules: {
  }
})
