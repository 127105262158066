<template>
    <div
        class="card"
        :style="get_style"
    >
        <div class="ellipse ellipse-color"></div>
        <div class="text-card-left">{{ card_deck.text }}</div>
        <div class="text-card">{{ card_deck.text }}</div>
        <div class="text-card-right">{{ card_deck.text }}</div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "CardShowView",
    computed: {
        ...mapState(["game"]),
        card_deck() {
            if (this.game?.discard?.length) {
                return this.game?.discard?.at(0)
            }
            return {}
        },
        get_style() {
            let card_class = []
            if (this.card_deck.color) card_class.push(`--card-color:${this.card_deck.color}`)
            if (this.card_deck.change_color) card_class.push(`--ellipse-color:${this.card_deck.change_color}`)
            return card_class.join(" ")
        }
    }
}
</script>

<style>
:root {
    --ellipse-color: var(--light);
}
.ellipse-color {
    border-color: var(--ellipse-color);
}
</style>