<template>
  <div class="card">
      <img
              :src="require('../assets/logo.svg')"
              alt="UNO"
              draggable="false"
      >
  </div>
</template>

<script>
export default {
    name: "CardHiddenView"
}
</script>

<style scoped>
img {
    width: 100%;
    margin: auto;
}
</style>