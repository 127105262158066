<template>
  <div id="player_list">
      <div v-if="game?.current_plus">+{{game.current_plus}}</div>
      <div
              v-for="(player, index) in game?.players"
              :key="index"
      >
          <v-divider class="border-opacity-100 my-1"></v-divider>
          <div class="d-flex">
              {{ player?.pseudo }}
              <v-icon
                  class="mx-2"
                  icon="mdi-cards-outline"
              ></v-icon>
              {{ player?.hand?.length }}
          </div>
      </div>
      <v-dialog
              :model-value="!game?.status && !current_pseudo"
              transition="dialog-bottom-transition"
              width="auto"
              :min-width="250"
              persistent
      >
          <v-card class="pa-3">
              <v-text-field
                      v-model="pseudo"
                      :error-messages="error_dialog_pseudo"
                      variant="outlined"
                      placeholder="Chose a pseudo"
                      autofocus
                      autocomplete="off"
                      counter
                      persistent-counter
                      :maxlength="15"
              ></v-text-field>
              <v-btn
                      @click="chose_pseudo"
                      :disabled="pseudo.trim().length < 3"
                      text="Play"
                      variant="tonal"
                      color="blue"
                      class="mt-1"
              ></v-btn>
          </v-card>
      </v-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "PlayerListView",
    props: {
        current_pseudo: String,
        error_dialog_pseudo: String
    },
    data: () => ({
        pseudo: ""
    }),
    computed: {
      ...mapState(["game"])
    },
    methods: {
        chose_pseudo() {
            this.$emit("chose_pseudo", this.pseudo.trim())
        }
    }
}
</script>

<style scoped>
#player_list {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    min-width: 200px;
    background-color: var(--light);
}
.d-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
}
</style>