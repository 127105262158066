<template>
  <div id="picker" v-if="current_card">
      <div class="cadre">
          <div
                  v-if="game?.option_game?.has_purple"
                  @click="set_color('#B512FF')"
                  class="purple_square square"
          ></div>
          <div class="d-grid h-100">
              <div
                      @click="set_color(color)"
                      v-for="(color, index) in colors"
                      :key="index"
                      :style="`background-color: ${color}`"
                      class="square"
              ></div>
          </div>
      </div>

  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
    name: "ColorPickerView",
    data: () => ({
        colors: ["#00D000", "#2E2EFF", "#FFBF00", "#FF1F1F"]
    }),
    computed: {
      ...mapState(["current_card", "game"])
    },
    methods: {
        ...mapMutations(["set_current_card"]),
        set_color(color) {
            this.$emit("play_card", {
                ...this.current_card,
                change_color: color
            })
            this.set_current_card(null)
        }
    }
}
</script>

<style scoped>
#picker {
    position: absolute;
    inset: 0;
    align-items: center;
    margin-top: 10px;
}
.cadre {
    z-index: 25;
    position: relative;
    margin: 0 auto;
    background-color: var(--dark);
    border-radius: 25%;
    height: 250px;
    width: 250px;
    overflow: hidden;
}
.d-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.purple_square {
    position: absolute;
    background-color: #B512FF;
    height: 50%;
    width: 50%;
    border-radius: 25%;
    left: 25%;
    top: 25%;
}
.square:hover {
    box-shadow: inset 0 0 10px var(--dark);
}
</style>