<template>
    <div
        @click="play_card"
        class="card"
        :class="playable ? 'card-hover' : ''"
        :style="card.color ? `--card-color:${card.color}`:''"
    >
        <div class="ellipse"></div>
        <div class="text-card-left">{{ card.text }}</div>
        <div class="text-card">{{ card.text }}</div>
        <div class="text-card-right">{{ card.text }}</div>
    </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
    name: "CardView",
    props: {
        card: Object
    },
    computed: {
        ...mapState(["game"]),
        playable() {
            const card = this.card
            const discard_card = this.game.discard.at(0);
            if (discard_card.another_number) return card.text === discard_card.text
            if (this.game.current_plus && isNaN(card.plus)) return false;
            if (!card.color) return true;
            if (card.color === discard_card.change_color) return true;
            if (card.color === discard_card.color) return true;
            if (card.text === discard_card.text) return true;
            return false;
        }
    },
    methods: {
        ...mapMutations(["set_current_card"]),
        play_card() {
            if (!this.playable) return;
            if (this.card.color) {
                this.$emit("play_card", this.card)
            } else {
                this.set_current_card(this.card)
            }
        }
    }
}
</script>

<style>
:root {
    user-select: none;
    --dark: #000000;
    --light: #FFFFFF;
    --card-color: #222222;
    font-family: "DejaVu Sans Mono";
    font-family: "Material Design Icons";
}
.card:first-child {
    margin-left: 0;
}
.card {
    position: relative;
    box-sizing: border-box;
    border: 3px solid var(--dark);
    border-radius: 10px;
    height: 250px;
    width: 150px;
    margin-left: -50px;
    background: var(--card-color);
    display: grid;
    overflow: hidden;
    transition: 250ms ease;
    will-change: transform;
}
.card-hover:hover {
    transform: translateY(-10px) translateX(-25px);
    z-index: 10;
}
.text-card {
    margin: auto;
    font-size: 80px;
}
[class^="text-card"] {
    color: var(--light);
    text-shadow: 2px 2px 2px var(--dark);
}
[class^="text-card-"] {
    font-size: 40px;
    padding: 0 2px;
    position: absolute;
}
.text-card-right {
    bottom: 0;
    right: 0;
}
.ellipse {
    box-sizing: border-box;
    border: 10px solid var(--light);
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    /*box-shadow: 0 0 10px var(--dark);*/
    transform: matrix(0.8,0.4,-0.4,0.8,0,0);
}
</style>