<template>
  <div>
<!--      todo dialog rules -->
      <GameOptionView
          @start_game="start_game"
      ></GameOptionView>
      <PlayerListView
          @chose_pseudo="chose_pseudo"
          :error_dialog_pseudo="error_dialog_pseudo"
          :current_pseudo="current_pseudo"
      ></PlayerListView>
      <InfoView
          @reset_info="reset_info"
          :info="info"
      ></InfoView>
      <div v-if="game.status">
          <ColorPickerView @play_card="play_card"></ColorPickerView>
          <DeckView @draw_card="draw_card"></DeckView>
          <HandView
              @play_card="play_card"
              @skip_turn="skip_turn"
              :current_pseudo="current_pseudo"
          ></HandView>
      </div>
  </div>
</template>

<script>
import io from "socket.io-client";
import { defineComponent } from 'vue';
import HandView from "@/components/HandView.vue";
import DeckView from "@/components/DeckView.vue";
import {mapMutations, mapState} from "vuex";
import ColorPickerView from "@/components/ColorPickerView.vue";
import PlayerListView from "@/components/PlayerListView.vue";
import GameOptionView from "@/components/GameOptionView.vue";
import InfoView from "@/components/InfoView.vue";
export default defineComponent({
    name: 'GameBoardView',
    components: {InfoView, GameOptionView, PlayerListView, ColorPickerView, DeckView, HandView},
    data: () => ({
        socket: null,
        info: null,
        error_dialog_pseudo: "",
        current_pseudo: null,
        card_draw_song: new Audio(require("../assets/card_draw_song.wav")),
    }),
    computed: {
      ...mapState(["card_deck", "game"])
    },
    methods: {
        ...mapMutations(["update_card_deck", "update_game", "add_time", "reset_interval", "set_current_card"]),
        get_response(error) {
            if (error) {
                this.display_info({
                    text: `Error : ${error}`,
                    icon: "alert-octagon-outline",
                    color: "red"
                })
                console.error(error)
            } else {
                this.display_info(null)
            }
        },
        play_draw_song() {
            const audio = this.card_draw_song
            audio.currentTime = 0
            audio.play()
        },
        chose_pseudo(pseudo) {
            this.socket.emit("login", pseudo, (game) => {
                if (game.error) {
                    this.error_dialog_pseudo = game.error
                    this.get_response(game.error)
                } else {
                    this.current_pseudo = pseudo
                    this.update_game(game)
                }
            })
        },
        start_game(game_option) {
            this.socket.emit("start_game", game_option, (error) => {
                this.get_response(error)
                if (!error) {
                    this.play_draw_song()
                }
            })
        },
        play_card(card) {
            this.socket.emit("play_card", this.current_pseudo, card, (error) => {
                this.get_response(error)
                if (!error) {
                    this.play_draw_song()
                }
            })
        },
        draw_card() {
            this.socket.emit("draw_card", this.current_pseudo, (error) => {
                this.get_response(error)
                if (!error) {
                    this.play_draw_song()
                }
            })
        },
        skip_turn() {
            this.socket.emit("skip_turn", this.current_pseudo, (error) => {
                this.get_response(error)
            })
        },
        display_info(info) {
            this.set_current_card(null)
            this.info = info
        },
        reset_info() {
            this.info = null
        },


        wait(ms) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, ms);
            })
        }
    },
    async mounted() {
        if (process.env.NODE_ENV === 'production') {
            this.socket = io.connect("uno.nexford.fr");
        } else {
            this.socket = io.connect("http://localhost:3000");
        }

        this.socket.emit("get_game", this.update_game)
        this.socket.on("update_game", this.update_game)
        this.socket.on("info", this.display_info)
        this.socket.on("reset_interval", this.reset_interval)
        this.socket.on("reset_game", () => {
            setTimeout(()=>{
                this.current_pseudo = null
            }, 2000)
        })
        setInterval(this.add_time, 1000)
    },
    beforeUnmount() {
        this.socket.disconnect();
    }
});
</script>
